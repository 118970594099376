<template>
  <div class="registerPage">
    <div class="container">
      <div class="registerBox">
         <div class="block">
        <el-image
          :src="ossHost + 'login_logo.png'"
          alt=''
          fit="contain"></el-image>
      </div>
        <el-form :model="registerForm" :rules="registerRule" ref="registerForm" label-width="234px">
          <el-form-item label="" prop="phone">
            <el-input v-model="registerForm.phone" prefix-icon="el-icon-phone-outline" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item label="" prop="password">
            <el-input v-model="registerForm.password" prefix-icon="el-icon-lock" type="password" placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item prop="role">
            <el-select v-model="registerForm.role" clearable placeholder="请选择角色">
              <el-option v-for="item in roleList" :key="item.ID" :label="item.Name" :value="item.ID"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="verifyCode" style="display:flex;">
            <el-input v-model="registerForm.verifyCode" style="width:110px;margin-right:10px;" prefix-icon="el-icon-user" placeholder="验证码"></el-input>
            <el-button type="primary" style="width:180px;" @click="getVerifyCode" :disabled="!verifyCooling">{{verifyCoolingTime==0 || verifyCoolingTime==60 ? '发送验证码': `${verifyCoolingTime}秒重新获取`}}</el-button>
          </el-form-item>
          <el-form-item>
            <el-button class="register" type="primary" @click="register('registerForm')" :loading="loading">注册</el-button>
          </el-form-item>
          <el-form-item>
            <el-button class="register" type="primary" @click="toLogin">去登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { register, getRegisterRole, sendCode } from '@/api/register'
import basicMixin from '@/mixins/basic'
import md5 from 'js-md5'
import verify from '@/utils/verify'
export default {
  mixins: [basicMixin],
  data() {
    return {
      loading: false, //是否正在注册中
      verifyCooling: true, //是否能按下发送验证码按钮
      verifyCoolingTime: 60, //剩下多少秒才能发送验证码
      roleList: [], //注册时可选择的角色列表
      registerForm: {
        phone: '', //手机号
        password: '', //密码
        role: '', //角色
        verifyCode: '' //验证码
      },
      registerRule: {
        phone: [{ required: true, message: '请输入正确的手机号码', trigger: 'change' }],
        password: [{ required: true, message: '请输入密码', trigger: 'change' }],
        role: [{ required: true, message: '请选择一个角色', trigger: 'change' }],
        verifyCode: [{ required: true, message: '请输入验证码', trigger: 'change' }],
      },
    }
  },
  methods: {
    //发送验证码
    getVerifyCode() {
      //没填写手机号提示填写手机号
      if (!this.registerForm.phone) {
        this.$message.warning('请填写接收验证码的手机号')
        return
      }else
      //正在倒计时中直接return
      if (!this.verifyCooling) return
      if (!verify._phone(this.registerForm.phone)) {
        this.$message.error('请输入正确的手机号')
        return
      }
      this.verifyCooling = false
      this.verifyCoolingTime = this.verifyCoolingTime - 1
      //倒计时60S才能再次发送验证码
      let timer = setInterval(() => {
        this.verifyCoolingTime = this.verifyCoolingTime - 1
        if (this.verifyCoolingTime === 0) {
          this.verifyCooling = true
          this.verifyCoolingTime = 60
          clearInterval(timer)
        }
      }, 1000)
      //发送验证码
      const params = {
        phone: this.registerForm.phone
      }
      
      sendCode(params).then(res => {
        if (res.code === 0) {
          this.$message.success('验证码发送成功')
        }
      })
    },
    //注册
    register(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          const params = {
            phone: this.registerForm.phone,
            password: md5(String(this.registerForm.password)), //密码需要md5加密传输过去
            code: this.registerForm.verifyCode,
            identityId: this.registerForm.role,
          }
          register(params).then(res => {
            //注册成功就去登录页
            this.$message({
              message: "注册成功",
              type: "success",
            });
            this.$router.push('/login')
          })
            .catch(() => { })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    // 回登录页
    toLogin(){
      this.$router.replace('/login')
    },
    //获取角色
    getRegisterRole() {
      getRegisterRole().then(res => {
        this.roleList = res.data
      })
    }
  },
  created() {
    //获取注册角色列表
    this.getRegisterRole()
  },
}
</script>

<style scoped lang="scss">
.registerPage {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('https://res.gts56.com/fzkj/web/front/YSB_PC/login_bg2.png');
  background-repeat: no-repeat;
  background-size: cover;
  .container {
    width: 1000px;
    height: 700px;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    display: flex;
    .registerBox {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      img {
        width: 300px;
        height: 100px;
        margin-bottom: 50px;
      }
      .el-form {
        width: 300px;
        .register {
          width: 100%;
        }
      }
    }
  }
}
</style>
<style>
.registerBox .el-form .el-form-item__content {
  margin: 0 !important;
}
.registerBox .el-form .el-select {
  width: 100% !important;
}
</style>